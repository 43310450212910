import React from 'react';
import ApprovalsManager from 'common/types/users/approvalsManager';
import { connect } from 'react-redux';
import { ForgeTable } from '@tylertech/forge-react';

import { isEmpty } from 'lodash';
import I18n from 'common/i18n/index';
import { APPROVERS } from 'common/components/AssetBrowser/lib/constants';

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Co... Remove this comment to see the full error message
import ConfigHeader from './ConfigHeader';

interface Props {
  readonly approvers: Array<ApprovalsManager>;
}

const translationScope = 'shared.approvals.settings.approvers';

const approversTableColumnConfig = [
  {
    header: I18n.t('table.column_headers.user_name', { scope: translationScope }),
    property: 'displayName',
    sortable: true,
    initialSort: true
  },
  {
    header: I18n.t('table.column_headers.email', { scope: translationScope }),
    property: 'email',
    sortable: true
  }
];

export const Approvers: React.FunctionComponent<Props> = ({ approvers }) => {
  return (
    <div className="approver_configuration">
      <ConfigHeader section={APPROVERS} />

      <div className="approval-settings-section-body">
        <div className="approval-settings-section-description">
          {
            isEmpty(approvers) ?
              (
                <span className="no_approvers_message">
                  {I18n.t('no_approvers', { scope: translationScope })}
                </span>
              ) :
              (
                [
                  <span key={0} className="subtitle_message">
                    {I18n.t('subtitle', { scope: translationScope })}
                  </span>,
                  <div key={1} className="approver_table">
                    <ForgeTable
                      columnConfigurations={approversTableColumnConfig}
                      data={approvers} />
                  </div>
                ]
              )
          }

          <p>
            <a href="/admin/users/" className="manage-users">
              {I18n.t('user_management', { scope: translationScope })}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  approvers: state.approvers || []
});

export default connect(mapStateToProps)(Approvers);
